import { Table } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PollAnswerVoter } from '../types';

const PollVotersTable = ({ votes } : { votes: PollAnswerVoter[] }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>{t('Full name')}</th>
            <th>{t('Email')}</th>
            <th>{t('Vote time')}</th>
          </tr>
        </thead>
        <tbody>
          {
            votes.map((row) => (
              <tr key={`${row.answerId}-${row.parentId}-row`}>
                <td>{`${row.name} ${row.surname}`}</td>
                <td>{`${row.email}`}</td>
                <td>{`${moment(new Date(row.voteDate)).format('DD. MMM Y. HH:mm')}`}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  );
};

export default PollVotersTable;
